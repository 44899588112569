.App-header {
  display: flex;
  background: url('./images/header-dark-1600x1200.jpg');
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  background-color: #000;
  min-height: 40vh;
}

@media (min-width: 768px) {
  .App-header {
    min-height: 50vh;
    background-position: center 45%;
    background-size: 75%;
  }
}

@media (min-width: 1024px) {
  .App-header {
    min-height: 33vh;
  }
}

@media (prefers-color-scheme: light) {
  .App-header {
    background: url('./images/header-light-1600x1200.jpg');
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
    background-color: #fff;
    min-height: 40vh;
  }

  @media (min-width: 768px) {
    .App-header {
      min-height: 50vh;
      background-position: center 45%;
      background-size: 75%;
    }
  }

  @media (min-width: 1024px) {
    .App-header {
      min-height: 33vh;
    }
  }
}

.App-logo {
  pointer-events: none;
  width: 33vw;
  height: 100%;
  padding: 1rem;
}

@media (min-width: 768px) {
  .App-logo {
    width: 25vw;
    max-width: 15rem;
    padding: 1.5rem;
  }
}

.App-logo > svg {
  height: 100%;
}
