*,
*::before,
*::after {
  box-sizing: border-box;
}

body {
  /* SurveyJS specific overrides */
  --primary: #00c8ff; /* title, selection, CTA, progress bar: Valtech theme cyan */
  --primary-light: rgba(26, 183, 250, 0.1); /* ? */
  --foreground: #ededed; /* text color */
  --foreground-light: #ccc; /* description text color */
  --primary-foreground: #fff; /* ? */
  --secondary: #f60; /* ? */
  --background: #002934; /* survey panel color */
  --background-dim: #000; /* page background color: Valtech theme off-black */
  --background-dim-light: #111; /* input field color */
  --sjs-general-dim-forecolor: #fff; /* Section heading text color */
  --sjs-general-dim-forecolor-light: #ccc; /* Section description text color */
  --font-family: 'Helvetica Neue', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
    'Cantarell', 'Fira Sans', 'Droid Sans', 'Arial', sans-serif;

  background-color: var(--background-dim);
  margin: 0;
  padding: 0;
  font-family: var(--font-family);
  color: var(--foreground);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@media (prefers-color-scheme: light) {
  body {
    --primary: #286e41; /* title, selection, CTA: Valtech theme cyan */
    --foreground: #111; /* text color */
    --foreground-light: #666; /* description text color */
    --background: #ddf3e5; /* survey panel color */
    --background-dim: #fff; /* page background color: Valtech theme white */
    --background-dim-light: #fff; /* input field color */
    --sjs-general-dim-forecolor: #111; /* Section heading text color */
    --sjs-general-dim-forecolor-light: #666; /* Section description text color */
  }
}

@media (prefers-color-scheme: dark) {
  body {
    --red-light: rgba(230, 10, 62, 0.2); /* Error background color (a little more red to improve contrast) */
  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}
