:root {
  --base-unit: 0.5rem;
}

.sd-header__text {
  display: flex;
  align-items: center;
  text-align: center;
}

.sd-description {
  max-width: calc(84 * var(--base-unit, 8px));
}

.sd-table__cell--header:not(.sd-table__cell--empty) {
  min-width: calc(8 * var(--base-unit, 8px));
}

@media (prefers-color-scheme: dark) {
  /* Note: Needed due to hard-coded light styling in the core script of SurveyJS. */
  body > div:last-child [data-sv-drop-target-matrix-row] {
    background-color: var(--background, #333) !important;
  }

  .sd-rating__item,
  .sd-radio__decorator {
    box-shadow: inset 0px -1px 2px rgba(255, 255, 255, 0.6), inset 0px 1px 2px rgba(0, 0, 0, 1);
  }

  .sv-list__item:hover .sv-list__item-body,
  .sv-list__item:focus .sv-list__item-body {
    color: var(--background);
  }
}

.sd-input--error,
.sd-question__erbox--outside-question {
  background-color: var(--red-light, rgba(230, 10, 62, 0.1));
}

.sd-html a {
  color: #00c8ff;
}
